/*
==========================================================================
Table of Contents:
1. General Styles
2. Layout and Game Container
3. Navigation
4. Game Elements
5. Buttons
6. Modals
7. Media Queries
==========================================================================
*/

/* 1. General Styles
========================================================================== */
body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #ffffff;
  color: #1a1a1b;
}

h1 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

/* 2. Layout and Game Container
========================================================================== */
.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.date {
  font-size: 0.9rem;
  margin: 100px 0px 10px 0px;
  padding: 7px;
  background-color: #f0f0f0;
  border-radius: 5px;
  color: #787c7e;
}

.countdown-timer {
  text-align: center;
  font-size: .8rem;
  /* font-weight: bold; */
  color: #787c7e;
  padding-bottom: 10px;
}

/* 3. Navigation
========================================================================== */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #d3d6da;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px; /* Set a fixed height for the navbar */
  z-index: 1000; /* Ensure navbar stays on top */
}

/* 4. Game Elements
========================================================================== */
.game-info {
  display: flex;
  justify-content: space-between;
  width: 250px;
  font-size: 0.9rem;
  color: #787c7e;
}

.speed-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  padding: 4px;
  border-radius: 9999px; /* This makes it fully rounded */
}

.speed-button {
  margin: 0 2px;
  padding: 4px 8px;
  font-size: 0.7rem;
  color: #787c7e;
  background-color: #ffffff;
  border: none;
  border-radius: 9999px; /* This makes each button fully rounded */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.speed-button:hover {
  background-color: #e0e0e0;
}

.speed-button.active {
  background-color: #c7c7c7;
  color: #ffffff;
  font-weight: bold;
}

/* Adjusting arrow spacing */
.speed-button:nth-child(2) .speed-indicator {
  letter-spacing: -2px;
}

.speed-button:nth-child(3) .speed-indicator {
  letter-spacing: -3px;
}


.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3px;
  margin-bottom: 20px;
}

.square {
  width: 64px !important;
  height: 64px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  border: 2px solid #d3d6da;
  cursor: pointer;
  /* transition: all 0.1s ease; */
}

.square.disabled {
  pointer-events: none;
  opacity: 0.75;
} 

.game-over {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1rem 0;
  color: #787c7e;
}

.start-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px !important;
}

.start-game-container.hidden {
  visibility: hidden;
}

.playing-anonymously {
  text-transform: uppercase;
  margin-top: 5px;
  font-weight: bold;
  font-size: 0.8rem;
  color: #787c7e;
}

.already-played {
  text-transform: uppercase;
  margin-top: 5px;
  font-weight: bold;
  font-size: 0.8rem;
  color: #787c7e;
}

/* 5. Buttons
========================================================================== */
.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.auth-button {
  position: absolute;
  right: 0rem;
  background: none;
  border: none;
  font-size: 0.8rem;
  color: #1a1a1b;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 10px 10px !important;
}

.about-button {
  position: absolute;
  left: 0rem;
  background: none;
  border: none;
  font-size: 0.8rem;
  color: #1a1a1b;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 10px 10px !important;
}

.about-button:hover,
.auth-button:hover {
  text-decoration: underline;
}

.game-button {
  margin: 10px;
  padding: 8px 16px;
  font-size: 0.9rem;
  color: #ffffff;
  background-color: #6aaa64;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

.game-button:hover {
  background-color: #538d4e;
}

.game-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* 6. Modals
========================================================================== */
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 90%;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.modal-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

.modal-button.login {
  color: #ffffff;
  background-color: #6aaa64;
}

.modal-button.login:hover {
  background-color: #538d4e;
}

.modal-button.anonymous {
  color: #ffffff;
  background-color: #b3b3b3;  /* Light grey */
  font-size: .8rem;
}

.modal-button.anonymous:hover {
  background-color: #999999;  /* Slightly darker grey for hover effect */
}

.close {
  color: #aaa;
  float: right;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-content button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 0.9rem;
  color: #ffffff;
  background-color: #6aaa64;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-content button:hover {
  background-color: #538d4e;
}

.modal-content button.copied {
  background-color: #cccccc;
  cursor: default;
}

.modal-content pre {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
  margin-bottom: 15px;
}

.modal-content .auth-button {
  background-color: #6aaa64;
}

.modal-content .auth-button:hover {
  background-color: #538d4e;
}

.modal-content .anon-button {
  background-color: #787c7e;
}

.modal-content .anon-button:hover {
  background-color: #5a5e60;
}

/* 7. Media Queries
========================================================================== */
/* Add more media queries here for smaller devices */
@media (max-width: 375px) {
  .square {
    width: 55px !important;
    height: 55px !important;
    font-size: 1.3rem;
  }

  .date {
    margin: 100px 0px 10px 0px;
  }

  .game-info {
    width: 220px;
    font-size: 0.85rem;
  }

  .speed-button {
    padding: 4px 8px;
    font-size: 0.75rem;
  }

  .game-button {
    padding: 7px 14px;
    font-size: 0.85rem;
  }
  .speed-button {
    margin: 0 3px;
    padding: 5px 10px;
    font-size: 0.6rem;
    min-width: 35px;
  }
}

/* Bigger phones */
@media (min-height: 700px) {
  .game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top !important;
  }
  h1 {
    font-size: 2rem;
  }

  .auth-button {
    font-size: 0.9rem;
  }

  .date {
    font-size: 1rem;
    margin: 10px 0px 10px 0px;
  }

  .game-info {
    width: 300px;
    font-size: 1rem;
  }

  .grid {
    gap: 5px;
  }

  .square {
    width: 75px !important;
    height: 75px !important;
    font-size: 2rem;
    /* transition: all 0.1s ease; */
  }

  .square:hover {
  border-color: #d3d6da !important;
  }

  .speed-button {
    margin: 0 3px;
    padding: 5px 10px;
    font-size: 0.8rem;
    min-width: 35px;
  }

  .game-button {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .game-over {
    font-size: 1.5rem;
  }

  .modal-content {
    width: 80%;
  }
}

/* Wider screens */
@media (min-width: 630px) {
  h1 {
    font-size: 2rem;
  }

  .auth-button {
    font-size: 0.9rem;
  }

  .date {
    font-size: 1rem;
    margin: 100px 0px 10px 0px;
  }

  .game-info {
    width: 300px;
    font-size: 1rem;
  }

  .grid {
    gap: 5px;
  }

  .square {
    width: 100px !important;
    height: 100px !important;
    font-size: 2rem;
    transition: all 0.1s ease;
  }

  .square:hover {
    border-color: #878a8c !important;
  }

  .speed-button {
    margin: 0 3px;
    padding: 5px 10px;
    font-size: 0.8rem;
    min-width: 35px;
  }

  .game-button {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .game-over {
    font-size: 1.5rem;
  }

  .modal-content {
    width: 30%;
  }
}
