.App {
  text-align: center;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.square {
  width: 100px;
  height: 100px;
  background-color: #ddd;
  cursor: pointer;
}

.square.highlight {
  background-color: #ff0;
}

button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
}